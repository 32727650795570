import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Footer from '@components/Footer';
import Nav from '@components/Nav';
import { PrismicFooter, PrismicNav } from '@utils/typings/prismic-types';

interface Props extends PageProps {
  data: {
    prismicNav: PrismicNav;
    prismicFooter: PrismicFooter;
  };
}

export default function PrivacyPolicy({ data }: Props) {
  if (!data) return null;

  const nav = data.prismicNav;
  const footer = data.prismicFooter;

  return (
    <>
      <Nav nav={nav} />

      <div className="content-narrow">
        <h1 className="main-heading">Privacy Policy</h1>
        <h5 className="section-subheading"></h5>
        <p>
          Pry was acquired by Brex Inc. 
          and, as such, is subject to the same Privacy Policies.<br></br>
          You can access the full content of our 
          Privacy Policy at {' '}
            <a href='https://www.brex.com/legal/privacy'
              className='link'
            >brex.com/legal/privacy</a>.
        </p>
        <h2 className="section-heading">Contact</h2>
        <p>
          You may contact us at{' '}
          <a href="mailto:support@pry.co?subject=Hi" className="link">
            support@pry.co
          </a>
          .
        </p>
      </div>

      <Footer footer={footer} />
    </>
  );
}

export const query = graphql`
  query privacyPolicyQuery($lang: String) {
    prismicNav(lang: { eq: $lang }) {
      ...NavFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
  }
`;
